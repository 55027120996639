const Eyes = {
    mousePosX: 0,
    mousePosY: 0,

    calculatePupilPosition(mouseX, mouseY, eyeRect) {
        if (!eyeRect) return {
            left: '50%',
            top: '50%'
        };

        const eyeCenterX = eyeRect.left + eyeRect.width / 2;
        const eyeCenterY = eyeRect.top + eyeRect.height / 2;

        // Вычисляем угол и расстояние
        const angle = Math.atan2(mouseY - eyeCenterY, mouseX - eyeCenterX);
        const distance = Math.min(
            eyeRect.width / 4,
            Math.hypot(mouseX - eyeCenterX, mouseY - eyeCenterY) / 2
        );

        // Вычисляем новые координаты
        const pupilX = Math.cos(angle) * distance;
        const pupilY = Math.sin(angle) * distance;

        return {
            left: `calc(50% + ${pupilX}px)`,
            top: `calc(50% + ${pupilY}px)`,
            transform: 'translate(-50%, -50%)'
        };
    },

    oncreate(vnode) {
        document.addEventListener('mousemove', (e) => {
            this.mousePosX = e.clientX;
            this.mousePosY = e.clientY;
            m.redraw();
        });
    },

    view() {
        return [
            // Background shapes
            m('.background-shapes', [
                m('.shape'),
                m('.shape'),
                m('.shape')
            ]),

            // Eyes
            m('.eyes-container', [
                // Left eye
                m('.eye', [
                    m('.pupil', {
                        style: this.calculatePupilPosition(
                            this.mousePosX,
                            this.mousePosY,
                            document.querySelector('.eye')?.getBoundingClientRect()
                        )
                    }),
                    m('.glow')
                ]),
                // Right eye
                m('.eye', [
                    m('.pupil', {
                        style: this.calculatePupilPosition(
                            this.mousePosX,
                            this.mousePosY,
                            document.querySelectorAll('.eye')[1]?.getBoundingClientRect()
                        )
                    }),
                    m('.glow')
                ])
            ])
        ];
    }
};

m.mount(document.body, Eyes);